<template>
  <div class="item-table">
    <p-header title="商品明细"></p-header>
    <el-form :model="queryParam" inline label-width="120" class="item-form">
      <el-form-item style="margin-right: 40px" label="商品名称">
        <el-input
          clearable
          v-model.trim="queryParam.merchandiseName"
          placeholder="请输入商品名称"
          maxlength="64"
        />
      </el-form-item>
      <el-form-item label="商品编码">
        <el-input
          clearable
          v-model.trim="queryParam.merchandiseCode"
          placeholder="请输入商品编码"
          maxlength="32"
        />
      </el-form-item>
      <el-form-item label="合规状态">
        <el-select
          v-model="queryParam.complianceStatus"
          multiple
          placeholder="请选择合规状态"
          collapse-tags
          clearable
          filterable
        >
          <el-option
            v-for="item in dictionary.dataComplianceStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-left: 32px">
        <el-button type="default" @click="handleReset">重置</el-button>
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      v-loading="loading"
      stripe
      border
      show-summary
      :summary-method="getSummaries"
      :header-cell-style="handleHeaderCellStyle"
    >
      <el-table-column
        type="index"
        label="序号"
        width="50"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="merchandiseName"
        label="商品名称"
        min-width="200"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="merchandiseCode"
        label="商品编码"
        width="180"
        fixed="left"
      ></el-table-column>
      <el-table-column
        prop="complianceStatus"
        label="合规状态"
        width="100"
        fixed="left"
      >
        <template slot-scope="scope">
          <compliance-status
            :value="scope.row.complianceStatus"
            :label="
              getDictLabel(
                dictionary.dataComplianceStatus,
                scope.row.complianceStatus
              )
            "
          ></compliance-status>
        </template>
      </el-table-column>
      <el-table-column
        prop="taxClassName"
        label="税分名称"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="taxClassCode"
        label="税分编码"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="specification"
        label="规格型号"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="unit"
        label="商品单位"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="quantity"
        label="商品数量"
        width="100"
        align="right"
        header-align="right"
      ></el-table-column>
      <el-table-column
        prop="price"
        label="含税单价"
        width="120"
        align="right"
        header-align="right"
      ></el-table-column>
      <el-table-column
        prop="taxRate"
        label="商品税率"
        width="100"
        align="right"
        header-align="right"
        :formatter="fmtTaxRate"
      />
      <el-table-column
        prop="isTaxPreferential"
        label="享受特税"
        width="80"
        align="center"
        header-align="center"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.isTaxPreferential == "1" ? "是" : "否" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="taxPreferentialContent"
        label="特税内容"
        min-width="100"
        align="left"
        header-align="left"
      />
      <el-table-column
        prop="amount"
        label="未税金额"
        width="120"
        align="right"
        header-align="right"
        :formatter="fmtAmount"
      />
      <el-table-column
        prop="taxAmount"
        label="商品税额"
        width="120"
        align="right"
        header-align="right"
        :formatter="fmtAmount"
      />
      <el-table-column
        prop="sumAmount"
        label="价税合计"
        width="120"
        align="right"
        header-align="right"
        :formatter="fmtAmount"
      />
      <el-table-column
        prop="discountSumAmount"
        label="折扣金额"
        width="120"
        align="right"
        header-align="right"
        :formatter="fmtAmount"
      />
      <el-table-column
        prop="complianceException"
        label="异常提示"
        min-width="200"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="color: red">
            {{ scope.row.complianceException }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="content-page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next"
        :current-page.sync="queryParam.pageNo"
        :page-sizes="pageSizes"
        :page-size="queryParam.pageSize"
        :total="dataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import PHeader from "@/components/pdt/PHeader";
import { fmtCurrency } from "@/assets/js/format-util.js";
import { reqPagingSettlementItem } from "@/service/coo/trade-item.js";
import { tableStyle, pagingMethods, changeMethods } from "@/util/mixins";
import ComplianceStatus from "@/views/cooperate/settlement/widgets/ComplianceStatus.vue";

export default {
  components: { PHeader, ComplianceStatus },
  name: "SettlementTradeItemList",
  props: {
    // 订单主键
    id: {
      type: String,
      require: true,
    },
    // 协同角色
    cooRole: {
      type: String,
      default: "SS",
    },
  },
  mixins: [tableStyle, pagingMethods, changeMethods],
  data() {
    return {
      // 数据字典
      dictionary: {
        dataComplianceStatus: [],
      },
      // 查询参数
      queryParam: {
        oriTradeId: this.id,
        merchandiseName: undefined,
        merchandiseCode: undefined,
        complianceStatus: [],
        pageNo: 1,
        pageSize: 15,
      },
      // 明细数据
      loading: false,
      tableData: [],
      dataTotal: 0,
    };
  },
  async created() {
    await this.initDict();
    this.handleQuery();
  },
  methods: {
    // 初始字典
    async initDict() {
      const { data } = await this.batchGetDicts({
        types: ["data_compliance_status"],
      });
      this.dictionary.dataComplianceStatus = data["data_compliance_status"];
    },
    // 重置条件
    handleReset() {
      this.queryParam = {
        oriTradeId: this.id,
        merchandiseName: undefined,
        merchandiseCode: undefined,
        complianceStatus: [],
        pageNo: 1,
        pageSize: 15,
      };
    },
    // 分页查询
    async handleQuery() {
      this.queryParam.pageNo = 1;
      await this.queryItems();
    },
    // 分页条数
    handleSizeChange(value) {
      this.queryParam.pageNo = 1;
      this.queryParam.pageSize = value;
      this.queryItems();
    },
    // 列表翻页
    handleCurrentChange(value) {
      this.queryParam.pageNo = value;
      this.queryItems();
    },
    // 执行查询
    async queryItems() {
      this.loading = true;
      const { success, data } = await reqPagingSettlementItem(this.queryParam);
      if (success) {
        this.tableData = data.list;
        this.dataTotal = data.total;
      }
      this.loading = false;
    },
    // 表格汇总
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "汇总";
        } else {
          let idx = [
            "amount",
            "taxAmount",
            "sumAmount",
            "discountSumAmount",
          ].indexOf(`${column.property}`);
          if (idx == -1) {
            sums[index] = "";
          } else {
            const values = data.map((item) => {
              if (idx == 0) {
                return Number(item.amount);
              } else if (idx == 1) {
                return Number(item.taxAmount);
              } else if (idx == 2) {
                return Number(item.sumAmount);
              } else {
                return Number(item.discountSumAmount);
              }
            });

            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + value;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = Number(sums[index]).toFixed(2) + " 元";
            } else {
              sums[index] = "";
            }
          }
        }
      });

      return sums;
    },
    // 格式税率
    fmtTaxRate(row, column, value) {
      const rate = Number(value);
      if (!isNaN(rate)) {
        return Number(rate * 100).toFixed(0) + "%";
      }
      return "*";
    },
    // 格式金额
    fmtAmount(row, column, value) {
      return fmtCurrency(value, 2, "", ",", ".", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.item-table {
  padding: 10px 20px;
  background: white;
  margin-top: 5px;

  .pdt-header {
    font-weight: 500;
    padding: 10px 0px 5px 0px;
    border-bottom: solid 1px #eeeeee !important;
  }

  .item-form {
    padding: 10px 0px;
  }
}
</style>
